import React from "react";
import { Layout } from "components";
import { ContactFormBlock, Loader, PostList } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { PageProps, graphql } from "gatsby";

interface ProjectFeedContext {
  pageNumber: number;
  humanPageNumber: number;
  skip: number;
  limit: number;
  numberOfPages: number;
  previousPagePath?: string;
  nextPagePath?: string;
}

const ProjectList = ({
  data: { page, posts, categories, wp },
  location,
  pageContext,
}: PageProps<Queries.ProjectsFeedQuery, ProjectFeedContext>): JSX.Element => {
  const allPosts = posts.nodes.map((node) => node);
  const allCategories = categories.nodes.map((node) => node);

  const {
    webAppSettings: {
      webAppSettings: { globalContactBlock },
    },
  } = wp;

  const { numberOfPages, currentPageNumber } = pageContext;

  if (page) {
    const { acfLayout, seo, content } = page;

    return (
      <Layout pageTitle={page.title} seo={seo}>
        <>
          {acfLayout?.layout?.length > 0 ? (
            <Loader
              data={acfLayout.layout.map(({ ...layout }) => ({
                ...layout,
                numberOfPages,
                currentPageNumber,
              }))}
              location={seo?.breadcrumbs}
              postListItems={allPosts}
              postListCategories={allCategories}
              browserLocation={location}
            />
          ) : (
            <PostList
              postListItems={allPosts}
              postListCategories={allCategories}
              browserLocation={location}
              data={{
                currentPageNumber: currentPageNumber,
                numberOfPages: numberOfPages,
              }}
            />
          )}
        </>
        {globalContactBlock && globalContactBlock.row && <ContactFormBlock data={globalContactBlock} />}
      </Layout>
    );
  }
};

export default WordPressPreview({ postType: "pages" })(ProjectList);

export const query = graphql`
  query ProjectsFeed($id: String!, $offset: Int!, $postsPerPage: Int!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      ...SEOPageQuery
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
          ...FlexibleBlockQuery
          ...PostListQuery
          ...FeaturedPostsQuery
        }
      }
    }
    categories: allWpProjectCategory(filter: { count: { gt: 0 } }) {
      nodes {
        id
        name
        slug
        uri
      }
    }
    posts: allWpProject(sort: { fields: [date], order: DESC }, limit: $postsPerPage, skip: $offset) {
      nodes {
        ...ProjectFields
      }
    }
    wp {
      webAppSettings {
        webAppSettings {
          globalContactBlock {
            row {
              contentType
              formId
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 80, placeholder: BLURRED, width: 700)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
